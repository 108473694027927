import {backendUrl, siteOrigin} from "../../globalConfig";

export const getActiveVTUList = async () => {
    return await fetch(backendUrl+'/vtu/get_active_vtu_list', {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': siteOrigin
        }
    })
        .then(r => r.json())
}
