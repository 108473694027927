import React, {useEffect, useState} from "react";
import {getActiveVTUList} from "../../asyncActions/vtu/getActiveVTUList";
import {IOneVTU} from "../../types/component_types";
import Box from "@mui/material/Box";
import {CssBaseline, Tooltip} from "@mui/material";
import VTUTable from "../../components/VTUTable/VTUTable";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const VTUPage: React.FC = () => {
    const [VTUArray, setVTUArray] = useState<IOneVTU[]>([]);
    useEffect(() => {
        const fetchData = async () => {
            const allVTU = await getActiveVTUList();
            setVTUArray(allVTU);
        };
        fetchData();
    },[]);

    const handleClick = () => {
        console.log('IconButton was clicked!');
    };

    return(
        <>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100vh',
                    flexGrow: 1
                }}
            >

                <div style={{marginTop: '16px'}}>
                    <Grid container >
                        <Grid item xs={12} sm={12} md={12}
                              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                            <IconButton
                                size="large"
                                // edge="start"
                                // color="inherit"
                                // aria-label="menu"
                                // sx={{ mr: 2 }}
                                onClick={handleClick}
                            >
                                <Tooltip title="Add new VTU" arrow>
                                    <span><AddCircleOutlineIcon /></span>
                                </Tooltip>

                            </IconButton>
                        </Grid>
                    </Grid>
                </div>

                <div style={{marginTop: '16px'}}>
                    {VTUArray.length > 0 ? (
                        <VTUTable VTUArray={VTUArray} setVTUArray={setVTUArray} />
                    ) : (
                        <></>
                    )}
                </div>
            </Box>

</>
    )
}

export default VTUPage;