import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CSVLink } from 'react-csv';
import Button from "@mui/material/Button";
import {IOneVTU} from "../../types/component_types";
import {Tooltip} from "@mui/material";

export interface IConversionProps {
    VTUArray: IOneVTU[];
    setVTUArray: Dispatch<SetStateAction<IOneVTU[]>>;
}

const VTUTable: React.FC<IConversionProps> = ({ VTUArray, setVTUArray }) => {
    const csvData = [
        ["symbol", "buy_count", "sell_count", "buy_status", "sell_status", "buy_price", "sell_price", "potential profit", "buy order created", "sell order created"],
        ...VTUArray.map(({ symbol, buy_count, sell_count, buy_price, sell_price,
                             buy_status, sell_status, buy_order_create_datetime, sell_order_create_datetime }) => [
            symbol, buy_count, sell_count, buy_status, sell_status, buy_price, sell_price,
            typeof(sell_count)=="number" && typeof(sell_price)== "number" && typeof(buy_count)=="number" && typeof(buy_price) == "number" ? (sell_count * sell_price - buy_count * buy_price) - (0.001*(sell_count * sell_price + buy_count * buy_price)): "",
            buy_order_create_datetime, sell_order_create_datetime
        ]),
    ];

    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                symbol
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                buy/sell count
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                buy/sell status
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                buy/sell price
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                Potential profit
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                buy created
                            </TableCell>
                            <TableCell style={{ fontWeight: 600 }} align="center">
                                sell created
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {VTUArray.map((one_vtu) => (
                            <TableRow key={one_vtu.vtu_id}>
                                <TableCell align="center">{one_vtu.symbol}</TableCell>
                                <TableCell align="center">{one_vtu.buy_count}/{one_vtu.sell_count}</TableCell>
                                <TableCell align="center">{one_vtu.buy_status} / {one_vtu.sell_status}</TableCell>
                                <TableCell align="center">{one_vtu.buy_price} / {one_vtu.sell_price}</TableCell>
                                <TableCell align="center">{typeof(one_vtu.sell_count)=="number" && typeof(one_vtu.sell_price)== "number" && typeof(one_vtu.buy_count)=="number" && typeof(one_vtu.buy_price) == "number" ? (one_vtu.sell_count * one_vtu.sell_price - one_vtu.buy_count * one_vtu.buy_price) - (0.001*(one_vtu.sell_count * one_vtu.sell_price + one_vtu.buy_count * one_vtu.buy_price)): "" }</TableCell>
                                <TableCell align="center">
                                    <Tooltip title={one_vtu.buy_order_create_datetime} arrow>
                                        <span>{typeof(one_vtu.buy_order_create_datetime)=='string' ? one_vtu.buy_order_create_datetime.substring(0,10) : ""}</span>
                                    </Tooltip>
                                </TableCell>
                                <TableCell align="center">
                                    <Tooltip title={one_vtu.sell_order_create_datetime} arrow>
                                        <span>{typeof(one_vtu.sell_order_create_datetime)=='string' ? one_vtu.sell_order_create_datetime.substring(0,10) : ""}</span>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button style={{ marginLeft: '2em', marginTop: '1em', marginBottom: '1em',
                borderRadius:'20px', fontFamily:'Montserrat', background: 'linear-gradient(180deg, #26DDD4, #07B7AD)'}}
                    variant="contained">
                <CSVLink
                    style={{ color: '#fff', textDecoration: 'none' }}
                    className="downloadbtn"
                    filename="conversion_table.csv"
                    data={csvData}
                >
                    Сформировать в CSV файл
                </CSVLink>
            </Button>
        </>
    );
};

export default VTUTable;
